
import Assets from './../../../../assets';

const {
    InstagramSectionAssets: {
        Insta1,
        Insta2,
        Insta3,
        Insta4
    }
} = Assets;

export default {
    title: '#EssaCadeiraÉMinha',
    description: 'Acesse o perfil @Catho_oficial pelo Instagram, compartilhe conosco uma foto do espaço que você conquistou no mercado e sirva de inspiração para milhares de mulheres.',
    list: [
        {
            img: Insta1,
        },
        {
            img: Insta2,
        },
        {
            img: Insta3,
        },
        {
            img: Insta4,
        },
        {
            img: Insta1,
        },
        {
            img: Insta2,
        },
        {
            img: Insta3,
        },
        {
            img: Insta1,
        },
    ]
};
