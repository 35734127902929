import React from 'react';

import './style.scss';
import InstagramSection from './InstagramSection';
import data from './mock';

function InstagramSectionContainer() {
    return (
        <InstagramSection {...data} />
    );
}

export default InstagramSectionContainer;
