import React from 'react';

import './style.scss';
import BlogSection from './BlogSection';
import data from './mock';

function BlogSectionContainer() {
    return (
        <BlogSection {...data} />
    );
}

export default BlogSectionContainer;
