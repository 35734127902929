import React from 'react';

import './style.scss';
import FeaturedSection from './FeaturedSection';
import data from './mock';

function FeaturedSectionContainer() {
    return (
        <FeaturedSection {...data} />
    );
}

export default FeaturedSectionContainer;
