export default {
    text: "Junte-se a nós nesta luta. Baixe o pacote de materiais e mantenha esta luta em pé.",
    link: {
        href: "/material_essacadeiraeminha.zip",
        target: "_self",
        rel: "nofollow",
        text: "DOWNLOAD"
    },
    googleAnalytics: {
        'data-gtm-event-category': "candidatos:catho",
        'data-gtm-event-label': "botao:download",
        'data-gtm-event-action': "dia-da-mulher:interação",
    },
};