import Assets from './../../../../assets';

const {
    RefactorSectionAssets: {
        RefactorThumb,

    },
} = Assets;

export default {
   content: {
       title: '',
       subTitle: '',
       video: {
           thumb: RefactorThumb
       }
   }
};
