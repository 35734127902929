import Assets from './../../../../assets';

const {
    FeaturedSectionAssets: {
        ActionsDesktop,
        ReportDesktop,
        VideoThumb
    }
} = Assets;

export default {
    list: [
        {
            asset: {
                type: 'iframe',
                props: {
                    src: 'https://www.youtube.com/embed/Wm9E8WPB2i0?enablejsapi=1&autoplay=1',
                    title: 'Essa cadeira é minha | Catho',
                    frameBorder: "0",
                    allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
                    allowFullScreen: true
                },
                placeHolderImage: VideoThumb,
            },
            text: {
                title: 'UMA LUTA POR IGUALDADE',
                description: 'Ainda que a nossa luta tenha avançado e tenhamos encontrado mais representatividade no mercado de trabalho, ela está longe de chegar ao fim. Só chegaremos ao fim quando formos tratadas igualitariamente em todos os postos de trabalho, do cargo mais baixo até a presidência da empresa.',
            },
            className: 'uma-luta-por-igualdade'
        },
        {
            asset: {
                type: 'image',
                src: ActionsDesktop,
                alt: 'Ações #EssaCadeiraÉMinha'

            },
            text: {
                title: 'Ações <span class="hashtag">#EssaCadeiraÉMinha</span>',
                description: 'A igualdade no mercado de trabalho tem avançado mas há setores que ainda são dominados por homens. A Catho quer mudar esse cenário desigual com ações como o programa Dev{elas} - construído para desenvolver mulheres que querem aprender e atuar na área tech; Plug-in de vagas - extensão do navegador para deixar os anúncios de vagas de emprego no feminino; ONU Mulheres - Pacto com os Princípios de Empoderamento das Mulheres; Membro da Coalização Empresarial do Instituto Avon, pelo fim da violência contra mulheres e meninas; além de lives, podcast e muitas outras ações.',
            },
            className: 'acoes-essa-cadeira-e-minha'
        },
        {
            asset: {
                type: 'image',
                src: ReportDesktop,
                alt: 'CANAL DE DENÚNCIA CATHO'

            },
            text: {
                title: 'CANAL DE DENÚNCIA CATHO',
                description: 'A nossa plataforma é segura e contra qualquer tipo de conduta inadequada no mercado de trabalho, por isso, mantemos um canal de denúncia onde candidatos podem nos alertar sobre qualquer empresa que atue de forma contrária a nossa ideologia. Para denunciar, é só acessar a área do candidato, clicar na opção Meus Currículos e na sequência denunciar a empresa. Após receber o formulário, nosso time de especialistas analisará a denúncia e, caso haja necessidade, bloqueará o acesso da empresa à plataforma da Catho.',
            },
            className: 'canal-de-denuncia-catho'
        }
    ]
};
