import React from 'react';
import { array, string } from 'prop-types';
import './style.scss';

const propTypes = {
    title: string.isRequired,
    list: array.isRequired,
};

const InstagramSection = ({ title, description, list }) => {
    return (
        <div className="InstagramSection">
            <div className="insta-title">
                {title}
            </div>
            <div className="insta-description">
                {description}
            </div>
            <div className="show-on-desktop">
                <div className="posts-desktop">
                    {list.map((item, index) => {
                        const { img } = item
                        return (
                            <div className="article" key={index}>
                                <img src={img} alt="instagram" />
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="show-on-mobile">
                <div className="posts-mobile">
                    <div className="article-row" >
                        <div className="article" >
                            <img src={list[0].img} alt="instagram" />
                        </div>
                        <div className="article" >
                            <img src={list[1].img} alt="instagram" />
                        </div>
                    </div>
                    <div className="article-row" >
                        <div className="article" >
                            <img src={list[2].img} alt="instagram" />
                        </div>
                        <div className="article" >
                            <img src={list[3].img} alt="instagram" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

InstagramSection.propTypes = propTypes;

export default InstagramSection;
