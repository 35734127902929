import React from 'react';
import { array, string, object } from 'prop-types';
import './style.scss';

const propTypes = {
    title: string.isRequired,
    text: string.isRequired,
    list: array.isRequired,
    button: string.isRequired,
    buttonText: string.isRequired,
    googleAnalytics: object.isRequired
};

function ChairsSection({
    title,
    text,
    list,
    button,
    buttonText,
    googleAnalytics
}) {
    return (
        <div className="chairs-section">
            <div className="container gtm-class">
                <h4>{title}</h4>
                <span>{text}</span>
                {list.map((item) => {
                    const { title, text, img } = item
                    return (
                        <div className="chair-item" key={title}>
                            <img src={img} alt="title" />
                            <h5>{title}</h5>
                            <span>{text}</span>
                        </div>
                    )
                })}
                <a type="button" rel="noopener noreferrer" className="button" href={button} {...googleAnalytics} target="_blank">
                    {buttonText}
                </a>
            </div>
        </div>
    );
};

ChairsSection.propTypes = propTypes;

export default ChairsSection;