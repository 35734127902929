import React from 'react';
import { object } from 'prop-types';
import './style.scss';

import ShareDialog from '../../ShareDialog';

const propTypes = {
    logo: object.isRequired,
    logoEssacadeiraeminha: object.isRequired,
    social: object.isRequired,
    main: object.isRequired,
};

function HeaderSection({
    logo,
    logoEssacadeiraeminha,
    social,
    main,
    mainSplitImage,
    mainSplitText,
}) {
    return (
        <header className="App-header">
            <div className="content">
                <div className="top gtm-class">
                    <div className="logo-chair">
                        <img src={logoEssacadeiraeminha.img} alt={logoEssacadeiraeminha.alt} />
                    </div>
                    

                    <div className="logo">
                        <a href={logo.a.href} title={logo.a.title} {...logo.a.googleAnalytics}>
                            <img src={logo.img.src} alt={logo.img.alt} />
                        </a>
                    </div>
            
                    <div className="social">
                        <div className="compartilhe">Compartilhe</div>
                        <div className="icons">

                        <div className="item">
                            <ShareDialog>
                                <a href={social.facebook.a.href} title={social.facebook.a.title} {...social.facebook.a.googleAnalytics}>
                                    <img src={social.facebook.img.src} alt={social.facebook.img.alt} />
                                </a>
                            </ShareDialog>
                        </div>
                        <div className="item">
                            <ShareDialog>
                                <a href={social.twitter.a.href} title={social.twitter.a.title} {...social.twitter.a.googleAnalytics}>
                                    <img src={social.twitter.img.src} alt={social.twitter.img.alt} />
                                </a>
                            </ShareDialog>
                        </div>
                        </div>
                    </div>
                </div>

                <div className="show-on-desktop">
                    <div className="main-image">
                        <img src={main.img.src} alt={main.img.alt} />
                    </div>
                </div>
                <div className="show-on-mobile">
                    <div className="main-image-split">
                        <img src={mainSplitImage.img.src} alt={mainSplitImage.img.alt} />
                    </div>
                </div>
            </div>
        </header>
    );
};

HeaderSection.propTypes = propTypes;

export default HeaderSection;
