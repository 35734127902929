import React from 'react';

import './style.scss';
import DownloadSection from './DownloadSection';
import data from './mock';

function DownloadSectionContainer() {
    return (
        <DownloadSection {...data} />
    );
}

export default DownloadSectionContainer;