import React from 'react';

import './style.scss';
import HeaderSection from './HeaderSection';
import data from './mock';

function HeaderSectionContainer() {
    return (
        <HeaderSection {...data} />
    );
}

export default HeaderSectionContainer;
