import React from 'react';
import { number, object, shape } from 'prop-types';

const propTypes = {
    dialog: shape({
        width: number.isRequired,
        height: number.isRequired,
    }),
    children: object.isRequired,
};

const defaultProps = {
    dialog: {
        width: 500,
        height: 400,
    }
}

const createLinksProps = ({ width, height }, { props }) => ({
    onClick: (e) => {
        e.preventDefault();
            window.open(props.href, "_blank", `width=${width},height=${height}`)
    },
})

const ShareDialog = ({ dialog, children }) => {
    return React.Children.map(children, child =>
        React.cloneElement(child, { ...createLinksProps(dialog, child) })
    );
}

ShareDialog.propTypes = propTypes;
ShareDialog.defaultProps = defaultProps;

export default ShareDialog;