import Assets from './../../../../assets';

const {
    BlogSectionAssets: {
        Blog1,
        Blog2,
        Blog3,
        Blog4
    }
} = Assets;

export default {
    title: 'O QUE ESTAMOS FALANDO SOBRE ISSO',
    list: [
        {
            img: Blog1,
            link: {
                url: 'https://www.catho.com.br/carreira-sucesso/diversidade/a-importancia-de-incluir-mulheres-trans-e-travesti-no-mercado-tech',
                alt: 'Imagem do blog 1',
            },
            text: 'A importância da inclusão de mulheres trans e travesti no no mercado Tech',
            googleAnalytics: {
                'data-gtm-event-category': "candidatos:catho",
                'data-gtm-event-label': "link:mulheres-trans-e-travesti-mercado-tech:ver-mais",
                'data-gtm-event-action': "dia-da-mulher:interação",
            },
        },
        {
            img: Blog2,
            link: {
                url: 'https://www.catho.com.br/carreira-sucesso/diversidade/catho-e-uma-das-melhores-empresas-para-mulheres-e-faz-parte-do-compromisso-onu-mulheres',
                alt: 'Imagem do blog 2',
            },
            text: 'Catho é uma das melhores empresas para mulheres trabalhar',
            googleAnalytics: {
                'data-gtm-event-category': "candidatos:catho",
                'data-gtm-event-label': "link:catho-uma-das-melhores-empresas-para-mulheres-trabalhar:ver-mais",
                'data-gtm-event-action': "dia-da-mulher:interação",
            },
        },
        {
            img: Blog3,
            link: {
                url: 'https://www.trampapo.com.br/episodes/mulheres-na-tecnologia',
                alt: 'Imagem do blog 3'
            },
            text: 'Mulheres na Tecnologia: you code, girl!',
            googleAnalytics: {
                'data-gtm-event-category': "candidatos:catho",
                'data-gtm-event-label': "link:mulheres-na-tecnologia:ver-mais",
                'data-gtm-event-action': "dia-da-mulher:interação",
            },
        },
        {
            img: Blog4,
            link: {
                url: 'https://www.trampapo.com.br/conteudo-extra/mulheres-tech-e-empresas',
                alt: 'Imagem do blog 4',
            },
            text: 'O que as mulheres na tecnologia devem buscar nas empresas?',
            googleAnalytics: {
                'data-gtm-event-category': "candidatos:catho",
                'data-gtm-event-label': "link:mulheres-tech-e-empresas:ver-mais",
                'data-gtm-event-action': "dia-da-mulher:interação",
            },
        }
    ]
};
