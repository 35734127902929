import React from 'react';

import './style.scss';
import RefactorSection from './RefactorSection';
import data from './mock';

function RefactorSectionContainer() {
    return (
        <RefactorSection {...data} />
    );
}

export default RefactorSectionContainer;
