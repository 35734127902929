import React from 'react';
import { array } from 'prop-types';
import './style.scss';
import YoutubeIframeLoader from '../../YoutubeIframeLoader';

const propTypes = {
    list: array.isRequired,
};

const renderAsset = ({ asset }) => {
    const { type } = asset;
    switch (type) {
        case 'iframe':
            const { props, placeHolderImage } = asset;
            return (
                <YoutubeIframeLoader placeHolderImage={placeHolderImage}>
                    <iframe title={props.title} {...props} />
                </YoutubeIframeLoader>
            )
        case 'image':
            const { src, alt } = asset;
            return <img src={src} alt={alt}></img>
        default: 
            return null;

    }
}

const FeaturedSection = ({ list }) => {
    return (
        <div className="FeaturedSection">
            <div className="featured-title">
                CONHEÇA O MOVIMENTO
            </div>
            {list.map(item => {
                return (
                    <div className={"featured-row " + item.className} key={item.text.title}>
                        <div className="featured-image">
                            {renderAsset(item)}
                        </div>
                        <div className="featured-text">
                            <div className="title" dangerouslySetInnerHTML={{ __html: item.text.title }}>
                            </div>
                            <div className="description" >
                                {item.text.description}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    );
};

FeaturedSection.propTypes = propTypes;

export default FeaturedSection;
