import React from 'react';

import './style.scss';
import ChairsSection from './ChairsSection';
import data from './mock';

function ChairsSectionContainer() {
    return (
        <ChairsSection {...data} />
    );
}

export default ChairsSectionContainer;
