import React from 'react';
import { array, string } from 'prop-types';
import './style.scss';

const propTypes = {
    title: string.isRequired,
    list: array.isRequired,
};

const BlogSection = ({ title, list }) => {
    return (
        <div className="BlogSection">
            <div className="blog-title">
                {title}
            </div>
            <div className="articles gtm-class">
                {list.map((item) => {
                    const { img, link, text, googleAnalytics } = item
                    return (
                        <div className="article" key={link.alt}>
                            <a rel="noopener noreferrer" target="_blank" href={link.url} {...googleAnalytics}>
                                <div className="article-cover">
                                </div>
                                <div className="article-text">
                                    <div>{text}</div>
                                </div>
                                <img src={img} alt={link.alt} />
                            </a>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

BlogSection.propTypes = propTypes;

export default BlogSection;
