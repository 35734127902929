import React, { Component } from 'react';

import HeaderSection from './components/sections/HeaderSection';
import RefactorSection from './components/sections/RefactorSection';
import FeaturedSection from './components/sections/FeaturedSection';
import DownloadSection from './components/sections/DownloadSection';
import ChairsSection from './components/sections/ChairsSection';
import BlogSection from './components/sections/BlogSection';
import InstagramSection from './components/sections/InstagramSection';
import FooterSection from './components/sections/FooterSection';

import loadAsyncScripts from './components/Html/loadAsyncScripts';

class App extends Component {
  componentDidMount() {
    window.addEventListener('load', () => {
      loadAsyncScripts();
    });
  }

  render() {
    return [
      <HeaderSection key="HeaderSection" />,
      <RefactorSection key="RefactorSection" />,
      <FeaturedSection key="FeaturedSection" />,
      <DownloadSection key="DownloadSection" />,
      <ChairsSection key="ChairsSection" />,
      <BlogSection key="BlogSection" />,
      <InstagramSection key="InstagramSection" />,
      <FooterSection key="FooterSection" />
    ];
  }
}


export default App;