import Assets from './../../../../assets';

const {
    ChairsSectionAssets: {
        chair_1,
        chair_2,
        chair_3,
        chair_4
    }
} = Assets;

export default {
    title: "O ESPAÇO DA MULHER NO MERCADO DE TRABALHO",
    text: "Fonte: Great Place To Work 2019",
    list: [
        {
            title: '16% DE MULHERES',
            text: 'PRESIDENTE/CEO',
            img: chair_1
        },
        {
            title: '26% DE MULHERES',
            text: 'ALTA LIDERANÇA (DIRETOR/GERENTE SÊNIOR)',
            img: chair_2
        },
        {
            title: '45% DE MULHERES',
            text: 'MÉDIA LIDERANÇA (GERENTE/COORDENADOR)',
            img: chair_3
        },
        {
            title: '56% DE MULHERES',
            text: 'PRESENÇA DA MULHER DE FORMA GERAL',
            img: chair_4
        }
    ],
    button: 'catho-busca-pela-equidade-de-genero.pdf',
    buttonText: 'VER PORCENTAGENS DA CATHO',
    googleAnalytics: {
        'data-gtm-event-category': "candidatos:catho",
        'data-gtm-event-label': "botao:ver-porcentagens-catho",
        'data-gtm-event-action': "dia-da-mulher:interação",
    },
};
