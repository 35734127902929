import React from 'react';
import { object, string } from 'prop-types';
import './style.scss';

const propTypes = {
    text: string.isRequired,
    logo: object.isRequired,
    hash: object.isRequired,
    scroll: object.isRequired,
    imgDesktop: string.isRequired,
    imgMobile: string.isRequired,
    functions: object.isRequired,
    footer: object.isRequired
};

function FooterSection({
    text,
    logo,
    hash,
    scroll,
    imgDesktop,
    imgMobile,
    functions,
    footer
}) {
    return (
        <footer className="footer-section">
            <div className="container">
                <div className="scroll-top gtm-class" onClick={functions.scroll}>
                    <img src={scroll.img.src} alt={scroll.img.alt} {...scroll.googleAnalytics} />
                    <span>{scroll.title}</span>
                </div>
                <img className="show-on-mobile" src={imgMobile} alt={text} />
                <img className="show-on-desktop" src={imgDesktop} alt={text} />
                <div className="copyright">
                    <div className="gtm-class">
                        <a href={logo.a.href} title={logo.a.title} {...logo.a.googleAnalytics}>
                            <img src={logo.img.src} alt={logo.img.alt} />
                        </a>
                    </div>
                    <div>
                        <img src={hash.src} alt={hash.alt} />
                    </div>
                </div>
            </div>
            <div className="footer-catho">
                <div className="container">
                    <div className="row left">
                        <div className="col">{footer.catho}</div>
                        <div className="col">{footer.cnpj}</div>
                    </div>
                    <div className="row right">
                        <div className="col">{footer.address}</div>
                        <div className="col">
                            <a href={footer.a.href} target="_blank" rel="noopener noreferrer" title={footer.a.title}>{footer.a.title}</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

FooterSection.propTypes = propTypes;

export default FooterSection;