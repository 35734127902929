import header_facebook from './header_section/facebook.png';

import header_logo from './header_section/logo.png';
import header_logo_essacadeiraeminha from './header_section/logo_essacadeiraeminha.png';
import header_main from './header_section/main.jpg';
import header_main_split_image from './header_section/main_split_image.jpg';
import header_twitter from './header_section/twitter.png';

import refactor_thumb from './refactor_section/refactor_thumb.jpg'
  
import actions_desktop from './featured_section/actions_desktop.png'
import report_desktop from './featured_section/report_desktop.png';
import video_thumb from './featured_section/video_thumb.jpg';

import chair_1 from './chairs_section/chair_1.png';
import chair_2 from './chairs_section/chair_2.png';
import chair_3 from './chairs_section/chair_3.png';
import chair_4 from './chairs_section/chair_4.png';

import blog1 from './blog_section/blog1.jpg';
import blog2 from './blog_section/blog2.jpg';
import blog3 from './blog_section/blog3.jpg';
import blog4 from './blog_section/blog4.jpg';

import insta1 from './instagram_section/insta1.png';
import insta2 from './instagram_section/insta2.png';
import insta3 from './instagram_section/insta3.png';
import insta4 from './instagram_section/insta4.png';

import footer_desktop from './footer_section/desktop.png';
import footer_mobile from './footer_section/mobile.png';
import footer_hash from './footer_section/hash.png';
import footer_logo from './footer_section/logo.png';
import footer_up from './footer_section/up.png';

const assets = {
    HeaderSectionAssets: {
        Facebook: header_facebook,
        Logo: header_logo,
        LogoEssacadeiraeminha: header_logo_essacadeiraeminha,
        Main: header_main,
        MainSplitImage: header_main_split_image,
        Twitter: header_twitter
    },
    RefactorSectionAssets: {
        RefactorThumb: refactor_thumb
    },
    FeaturedSectionAssets: {
        ActionsDesktop: actions_desktop,
        ReportDesktop: report_desktop,
        VideoThumb: video_thumb,
    },
    ChairsSectionAssets: {
        chair_1: chair_1,
        chair_2: chair_2,
        chair_3: chair_3,
        chair_4: chair_4
    },
    BlogSectionAssets: {
        Blog1: blog1,
        Blog2: blog2,
        Blog3: blog3,
        Blog4: blog4,
    },
    InstagramSectionAssets: {
        Insta1: insta1,
        Insta2: insta2,
        Insta3: insta3,
        Insta4: insta4,
    },
    FooterSectionAssets: {
        desktop: footer_desktop,
        mobile: footer_mobile,
        hash: footer_hash,
        logo: footer_logo,
        scrollUp: footer_up
    }
};

export default  { ...assets };