import React from 'react';
import './style.scss';

import { string, shape, object } from 'prop-types';
import image from '../../../assets/download_section/download_desktop.png';

const propTypes = {
    text: string.isRequired,
    link: shape({
        href: string.isRequired,
        target: string.isRequired,
        rel: string.isRequired,
        text: string.isRequired,
    }),
    googleAnalytics: object.isRequired
};
function DownloadSection({
    text,
    link: {
        href,
        target,
        rel,
        text: linkText,
    },
    googleAnalytics
}) {
    return (
        <div>
            <div className="DownloadSectionFirst" style={{ backgroundImage: `url(${image})` }}></div>
            <div className="DownloadSectionLink">
                <div className="paragraph">
                    <div className="text">{text}</div>
                    <div className="link gtm-class">
                        <a
                            href={href}
                            target={target}
                            rel={rel}
                            {...googleAnalytics}
                        >
                            <span className="ButtonLink">{linkText}</span>
                        </a>
                    </div>
                </div>
            </div>
            <div className="DownloadSectionSecond" style={{ backgroundImage: `url(${image})` }}></div>
        </div>
    );
};

DownloadSection.propTypes = propTypes;
export default DownloadSection;
