import React from 'react';

const PlayIcon = () => {
    return (
        <svg width="68" height="48" xmlns="http://www.w3.org/2000/svg">

        <g>
         <title>background</title>
         <rect fill="none" id="canvas_background" height="50" width="70" y="-1" x="-1"/>
        </g>
        <g>
         <title>Layer 1</title>
         <path stroke="null" fill="#000" id="svg_1" d="m57.290041,1.488264c-9.011249,-0.615085 -37.117018,-0.628687 -46.115765,-0.016102c-9.74385,0.665092 -11.057107,6.219737 -11.129617,21.711883c0.07251,15.464643 0.879022,21.375462 10.632873,22.043054c9.001247,0.612585 38.098004,0.615085 47.109252,0c9.74385,-0.665092 9.99921,-7.015472 10.071721,-22.507619c-0.07251,-15.464643 -0.814613,-20.563624 -10.568464,-21.231217zm-32.25847,21.265271l-0.165573,-9.405278l20.830736,10.149456l-20.499565,9.522119z"/>
         <path stroke="null" id="svg_6" d="m24.782226,13.460316l21.228912,10.18893l-21.211841,9.750605l-0.017071,-19.939535z" strokeOpacity="null" strokeWidth="null" fill="#ffffff"/>
        </g>
       </svg>
    )
}

export default PlayIcon;