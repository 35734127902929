import React, { Component } from 'react';
import { object, string } from 'prop-types';

import './style.scss';

import PlayIcon from './PlayIcon'

const propTypes = {
    placeHolderImage: string.isRequired,
    children: object.isRequired,
};

/**
 * Google Tag Manager: track dinamically inserted YouTube videos
 */
const createYoutubeIframeApiScript =  () => {
    const script = document.createElement("script");
    script.src = "https://www.youtube.com/iframe_api";
    document.body.appendChild(script);
}

class YoutubeIframeLoader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shouldLoadIframe: false,
        }
        this.onPlaceHolderClick = this.onPlaceHolderClick.bind(this);
    }

    onPlaceHolderClick() {
        createYoutubeIframeApiScript();
        this.setState({
            shouldLoadIframe: true
        })
    }

    render() {
        const { placeHolderImage, children } = this.props;
        const { shouldLoadIframe } = this.state;

        const child = React.Children.map(children, child =>
            React.cloneElement(child, { src: child.props.src + '&autoplay=1' })
        );

        return (
            <div className="iframe-wrapper" >
                {shouldLoadIframe &&
                    child
                }
                {!shouldLoadIframe &&
                    <div className="placeholder-wrapper" onClick={this.onPlaceHolderClick}>
                        <img src={placeHolderImage} alt="placeholder for video"/>
                        <PlayIcon />
                    </div>
                }
            </div>
        );
    }
}

YoutubeIframeLoader.propTypes = propTypes;

export default YoutubeIframeLoader;