import React from "react";
import { object } from "prop-types";
import "./style.scss";

import YoutubeIframeLoader from "../../YoutubeIframeLoader";

const propTypes = {
  content: object.isRequired,
};

function RefactorSection({
    content,
}) {
  return (
    <div className="main-content">
      <div className="main-content-description">
        <div className="text">
          <div className="title">
            Apenas 23% dos
            <br />
            profissionais de TI
            <br />
            são mulheres e elas
            <br />
            ganham até 17% a menos
            <br />
            que os homens.
          </div>
          <div className="sub-title">
            Vamos
            <br />
            reprogramar
            <br />
            esses números.
          </div>
        </div>
        <div className="video">
          <YoutubeIframeLoader placeHolderImage={content.video.thumb}>
            <iframe
              title="Reprogramando o mercado de trabalho"
              src="https://www.youtube.com/embed/754KCOAfUtY?enablejsapi=1&autoplay=1"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="true"
            ></iframe>
          </YoutubeIframeLoader>
        </div>
      </div>
      <div className="main-content-footer">
        * Fonte: Pesquisa Catho 2022.
      </div>
    </div>
  );
}

RefactorSection.propTypes = propTypes;

export default RefactorSection;
