import Assets from './../../../../assets';

const {
    FooterSectionAssets: {
        desktop,
        mobile,
        hash,
        logo,
        scrollUp
    }
} = Assets;

export default {
    text: "MANTENHA ESSA LUTA EM PÉ",
    logo: {
        a: {
            href: "https://www.catho.com.br",
            title: "Acessar a Catho",
            googleAnalytics: {
                'data-gtm-event-category': "candidatos:catho",
                'data-gtm-event-label': "link:footer-logo-catho",
                'data-gtm-event-action': "dia-da-mulher:interação",
            }
        },
        img: {
            src: logo,
            alt: "Logo Catho",
        }
    },
    hash: {
        src: hash,
        alt: "#EssaCadeiraÉMinha"
    },
    footer: {
        catho: 'Catho Online Ltda.',
        cnpj: 'CNPJ: 03.753.088/0001-00',
        address: 'Alameda Juari, 262 Barueri - SP - 06460-090',
        a: {
            href: 'https://seguro.catho.com.br/terms/politica-cookies-privacidade-v5.html',
            title: 'Política de Privacidade'
        }
    },
    scroll: {
        img: {
            alt: "voltar ao topo",
            src: scrollUp
        },
        title: "voltar ao topo",
        googleAnalytics: {
            'data-gtm-event-category': "candidatos:catho",
            'data-gtm-event-label': "botao:voltar-topo",
            'data-gtm-event-action': "dia-da-mulher:interação",
        }
    },
    imgDesktop: desktop,
    imgMobile: mobile,
    functions: {
        scroll: function(){}
    }
};
