import React from 'react';

import './style.scss';
import FooterSection from './FooterSection';
import data from './mock';

const attrs = {
    ...data,
    functions: {
        scroll: scrollTop
    }
}

function scrollTop() {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
}

function FooterSectionContainer() {
    return (
        <FooterSection {...attrs} />
    );
}

export default FooterSectionContainer;
