import Assets from './../../../../assets';

const {
    HeaderSectionAssets: {
        Facebook,
        Logo,
        LogoEssacadeiraeminha,
        Main,
        MainSplitImage,
        MainThumbFilme,
        Twitter
    },
} = Assets;

export default {
    logo: {
        a: {
            href: "https://www.catho.com.br",
            title: "Acessar a Catho",
            googleAnalytics: {
                'data-gtm-event-category': "candidatos:catho",
                'data-gtm-event-label': "link:logo-catho",
                'data-gtm-event-action': "dia-da-mulher:interação",
            },
        },
        img: {
            src: Logo,
            alt: "Logo Catho",
        }
    },
    logoEssacadeiraeminha: {
        img: LogoEssacadeiraeminha,
        alt: 'essa cadeira é minha'
    },
    social: {
        facebook: {
            a: {
                href: "https://www.facebook.com/sharer/sharer.php?u=https://www.essacadeiraeminha.com.br&src=sdkpreparse",
                title: "Catho no Facebook",
                googleAnalytics: {
                    'data-gtm-event-category': "candidatos:catho",
                    'data-gtm-event-label': "link:compartilhe-facebook",
                    'data-gtm-event-action': "dia-da-mulher:interação",
                },
            },
            img: {
                src: Facebook,
                alt: "Facebook",
            }
        },
        twitter: {
            a: {
                href: "https://twitter.com/share?text=" + encodeURIComponent("Até que os cargos de liderança sejam igualmente ocupados por mulheres, a luta permanece em pé! Conheça o movimento da @Catho pela equidade de gênero no mercado de trabalho. ♀") + "&url=https://www.essacadeiraeminha.com.br&hashtags=EssaCadeiraÉMinha,Catho",
                title: "Catho no Twitter",
                googleAnalytics: {
                    'data-gtm-event-category': "candidatos:catho",
                    'data-gtm-event-label': "link:compartilhe-twitter",
                    'data-gtm-event-action': "dia-da-mulher:interação",
                },
            },
            img: {
                src: Twitter,
                alt: "Twitter",
            }
        }
    },
    main: {
        img: {
            src: Main,
            alt: "Essa cadeira é minha",
        },
        content: {
            texts: {
            },
            thumb: {
                src: MainThumbFilme,
                alt: "Imagem 2 topo",
            }
        }
    },
    mainSplitImage: {
        img: {
            src: MainSplitImage,
            alt: "Essa cadeira é minha",
        }
    }
};
